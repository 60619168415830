import "./styles.scss"

import React from "react"

import Button from "components/Button"

import { useLocale } from "context/locale"

const Baner = () => {
  const { t } = useLocale()

  return (
    <section className="lands-baner">
      <div className="container-fluid">
        <h2>
          {t(
            "Rozważasz sprzedaż gruntu? Porozmawiajmy o wspólnych korzyściach."
          )}
        </h2>
        <Button to={t("/kontakt/")} className="button--circle">
          {t("więcej")}
        </Button>
      </div>
    </section>
  )
}

export default Baner
