import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

const Content = () => {
  const { t } = useLocale()

  const contentData = [
    {
      icon: require("assets/images/lands-content-ico-2.svg").default,
      title: t("Grunty mieszkaniowe"),
      description: t(
        "Mające lokalizację pod zabudowę wielorodzinną w miastach: Warszawa, Kraków, Łódź, Poznań, Wrocław, Lublin, Katowice, Gliwice, Gdańsk, Kielce, Radom, Częstochowa"
      ),
    },
    {
      icon: require("assets/images/lands-content-ico-1.svg").default,
      title: t("Grunty komercyjne"),
      description: t(
        "Mające dogodną lokalizację pod kątem inwestycji handlowych na terenie całej Polski"
      ),
    },
  ]

  return (
    <section className="lands-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <h3>
              {t(
                "Doskonale rozumiemy, że rozwój firmy deweloperskiej jest możliwy tylko wtedy, gdy dla przyszłych Klientów będziemy pozyskiwali grunty w najlepszych lokalizacjach."
              )}
            </h3>
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    "W każdym przedsięwzięciu przyświeca nam idea obopólnych korzyści, dlatego oferujemy bardzo korzystne warunki oraz pełne wsparcie w procesie sprzedaży gruntu. Poszukujemy gruntów w dogodnych lokalizacjach pod inwestycje mieszkaniowe i komercyjne. <a href='/kontakt/'>Skontaktuj się z nami</a> i porozmawiajmy o dobrej współpracy!"
                  ),
                }}
              />
            </p>
            {contentData.map((item, index) => (
              <div key={index}>
                <div className="lands-content__item">
                  <div className="lands-content__item-icon">
                    <img src={item.icon} alt="" />
                  </div>
                  <div>
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    <p>
                      Tel: <a href="tel:+48 796 875 475">+48 796 875 475</a>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-5">
            <img
              className="lands-content__image"
              src={require("assets/images/lands-content-image.jpg").default}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
