import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content, Baner } from "page_components/lands"

const Support = ({ location }) => {
  const title = "Купимо землю"

  return (
    <Layout
      location={location}
      seo={{
        title: "Купимо землю",
        description:
          "Тільки взаємна вигода. Ми пропонуємо дуже вигідні умови та повний супровід у процесі продажу землі. Поговоримо про плідну співпрацю!",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
      <Baner />
    </Layout>
  )
}

export default Support
